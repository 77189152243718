import { Avatar, Col, Row, Typography } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { getAllergyList } from '../../../../../api/Lists'
import moment from 'moment'
import icon from 'assets/Images/otherCategory.svg'
import { guardianStore } from 'state/family/guardian'
import { FamilyMemberGuardian } from 'types/family/guardian'
const { Text, Title } = Typography

const GuardianGeneral = ({ data }: { data: FamilyMemberGuardian }) => {
  const { setGuardianCreationModal, setGuardianData, setIsUpdating } =
    guardianStore(store => ({
      setGuardianCreationModal: store.setGuardianCreationModal,
      setGuardianData: store.setGuardianData,
      setIsUpdating: store.setIsUpdating,
    }))

  const handleEdit = () => {
    setGuardianData(data)
    setIsUpdating(true)
    setGuardianCreationModal(true)
  }

  const { data: AllergyData } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )
  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={allergy.icon} size={20} />
          <h2 className="text-16 pl-10">{allergy.name}</h2>
        </div>
      )
    } else {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={icon} size={20} />
          <h2 className="text-16 pl-10">{data?.name}</h2>
        </div>
      )
    }
  }
  return (
    <Col span={12}>
      <div className="profile_container">
        <Row justify="space-between" align="middle">
          <Col span={14}>
            <Title level={4} style={{ marginBottom: '0px' }}>
              General Details
            </Title>
          </Col>
          <Col span={10} className="text-right">
            <button className="bordered-prim-btn" onClick={handleEdit}>
              Edit Profile
            </button>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          {data?.dob && (
            <Row>
              <Col span={12}>
                <Text type="secondary" className="b1 font14 text-color-black">
                  Birthday
                </Text>
              </Col>
              <Col span={12} className="text-right">
                <Text className="b3 font14 text-color-black">
                  {}
                  {data?.dob
                    ? moment(data?.dob).format('DD MMM YYYY')
                    : 'No date of birth found'}
                </Text>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.gender && (
            <Row>
              <Col span={12}>
                <Text type="secondary" className="b1 font14 text-color-black">
                  Gender
                </Text>
              </Col>
              <Col span={12} className="text-right">
                <Text className="b3 font14 text-color-black">
                  {data?.gender?.charAt(0).toUpperCase() +
                    data?.gender?.slice(1)}
                </Text>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.medicalNotes && (
            <Row>
              <Col span={24}>
                <Text type="secondary" className="b1 font14 text-color-black">
                  Medical Notes
                </Text>
              </Col>
              <Col span={24} className=" flex-column">
                <Text className="b3 mt-5px font14 text-color-black">
                  {data?.medicalNotes}
                </Text>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.allergies?.length > 0 && (
            <Row>
              <Col span={24}>
                <Text
                  type="secondary"
                  className="b1 font14 text-color-black mb-10"
                >
                  Allergies
                </Text>
                <div className="flex  flex-wrap w-full">
                  {data?.allergies?.map((data: any) => {
                    return (
                      <div key={data?._id} className="mt-10px">
                        {getAllergywithIcon(data)}
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Col>
  )
}
export default GuardianGeneral
