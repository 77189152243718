import axios from 'axios'
import {
  addDropInBody,
  AtendeeStatusParams,
  CreateRollCallBody,
  updateRollCall,
} from 'pages/Events/types'
import { parseParams } from '../helper/utility'
import {
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPatchCheckout,
} from '../Redux/axiosHelper'
import {
  CategoryType,
  EventAnalyticsParams,
  RequestParms,
  ReviewsStatsTypes,
} from '../types/globalTypes'
import { BASE_URL } from './global'
import { notification } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { PaginatedEventsResp } from 'types/events'

export const getEvents = async (params: any) => {
  const pars = parseParams(params)
  const response = await axiosGet(`v1/organization-events?${pars}`)
  return response.data
}

export const getEventFaqs = async (eventId: string) => {
  const response = await axiosGet(`v1/event-questions/event/${eventId}`)
  return response.data
}
export const getFamilyEvents = async (familyId: any, params: RequestParms) => {
  const response = await axiosGet(`v1/families/${familyId}/events`, params)
  return response.data
}
export const getEventById = async (eventId: string) => {
  const response = await axiosGet(`v1/organization-events/${eventId}`)
  return response.data
}
export const getEventParticipants = async (id: string) => {
  const response = await axiosGet(`v1/manage-events/${id}/participants`)
  return response.data
}
export const getGuardianEvents = async (
  guardianId: string,
  params: RequestParms,
) => {
  const response = await axiosGet(`v1/guardians/${guardianId}/events`, params)
  return response.data
}
export const getEventAnalytics = async (
  orgId: string,
  params?: EventAnalyticsParams,
) => {
  const response = await axiosGet(
    `v1/organization-events/${orgId}/analytics`,
    params,
  )
  return response.data
}
export const getAttendieByDate = async (eventId: string, date: any) => {
  const response = await axiosGet(
    `v1/manage-events/${eventId}/participants/day?date=${date}`,
  )
  return response.data
}
export const getEventCheckInList = async (eventId: string) => {
  const response = await axiosGet(`v1/check/list/event/${eventId}`)
  return response.data
}
export const getEventGroups = async (eventId: string) => {
  const response = await axiosGet(`v1/manage-events/${eventId}`)
  return response.data
}
export const getEventAttendee = async (
  eventId: string,
  params: RequestParms,
) => {
  const response = await axiosGet(`v1/attendance/${eventId}`, params)
  return response.data
}
export const getExportEventAttendee = async (eventId: string) => {
  const response = await axiosGet(`v1/attendance/export-data/${eventId}`)
  return response.data
}
export const getEventAttendeeTickets = async (
  eventId: string,
  memberId: string,
) => {
  const response = await axiosGet(
    `v1/booking/get-participant-tickets/${eventId}`,
    { memberId },
  )
  return response.data
}
export const getEventAttendeesStatus = async (params: AtendeeStatusParams) => {
  const response = await axiosGet(`v1/check/participants-status`, params)
  return response.data
}
export const createEventRollCall = async (body: CreateRollCallBody) => {
  const response = await axios.post(`${BASE_URL}v1/roll-call/create`, body)
  return response
}
export const getEventRollCall = async (eventId: string, date: string) => {
  const response = await axiosGet(`v1/roll-call/history`, {
    eventId: eventId,
    date: date,
  })
  return response.data
}
export const updateEventRollCall = async (id: string, body: updateRollCall) => {
  const response = await axios.patch(`${BASE_URL}v1/roll-call/${id}`, body)
  return response
}
export const getEventDropInList = async (eventId: string, today: string) => {
  const response = await axiosGet(`v1/dropin/list/${eventId}`, { today })
  return response.data
}
export const getEventPastDropIns = async (eventId: string, date: string) => {
  const response = await axiosGet(`v1/dropin/past-list/${eventId}/${date}`)
  return response.data
}
export const createEventDropIn = async (body: addDropInBody) => {
  try {
    // const data = await createEventDropIn(body)
    const response = await axios.post(`${BASE_URL}v1/dropin/add-attendee`, body)
    return response.data
  } catch (error) {
    notification.error({
      // message: 'Error: Event has ended!',
      message: error.response.data.message,
    })
  }
}
export const getDropInCheckinData = async (
  memberId: string,
  date: string,
  checkInId: string,
  eventId: string,
) => {
  const response = await axiosGet(`v1/dropin/recent-check-in/${eventId}`, {
    memberId,
    checkInId,
    date,
  })
  return response.data
}
export const getMembersByEmail = async (
  email: string,
  today: string,
  eventId: string,
) => {
  const response = await axiosGet(`v1/dropin/get-members-by-email/${eventId}`, {
    email,
    today,
  })
  return response.data
}
export const checkoutWithOutAmount = async (
  email: string,
  checkInId: string,
  checkOutOrganizerId: string,
) => {
  const response = await axiosPost(
    { email, checkInId, checkOutOrganizerId },
    `v1/dropin/checkout-dropin-without-amount`,
  )
  return response.data
}
export const checkInParticipant = async (body: any) => {
  const response = await axios.post(
    `${BASE_URL}v1/check/organizer-check-in`,
    body,
  )
  return response.data
}
export const checkOutParticipant = async (body: any) => {
  const response = await axiosPatchCheckout(
    body,
    `v1/check/event-check-out-request`,
    body.checkOutType == 'request'
      ? `Participant request checkout successfully`
      : `Participant checkout successfully`,
  )
  return response.data
}
export const dropOutParticipant = async (body: any) => {
  const response = await axiosPatchCheckout(
    body,
    `v1/check/event-check-out-request`,
    `Participant dropped out successfully`,
  )
  return response.data
}
export const getParticpantTicketByDay = async (
  eventId: string,
  params: Record<string, string>,
) => {
  const response = await axiosGet(
    `v1/booking/get-admission-tickets/${eventId}`,
    params,
  )
  return response.data
}
export const getDropInTicket = async (eventId: string) => {
  const response = await axiosGet(
    `v1/organization-events/${eventId}/dropin-ticket`,
  )
  return response.data
}
export const getParticipantCheckInOutHistory = async (
  id: string,
  date: string,
  eventId: string,
) => {
  const response = await axiosGet(`v1/check/recent-check-in/member/${id}`, {
    date,
    eventId,
  })
  return response.data
}

export const createDropInIntent = async (body: any) => {
  const response = await axios.post(
    `${BASE_URL}v1/stripe/create-dropin-intent`,
    body,
  )
  return response.data
}
export const approveParticipant = async (checkInId: any) => {
  const response = await axiosPatchCheckout(
    checkInId,
    `v1/check/event-check-out-approval`,
    `Participant check-out approve successfully`,
  )
  return response.data
}
export const approveParticipantCheckIn = async (checkInId: any) => {
  const response = await axiosPatchCheckout(
    checkInId,
    `v1/check/event-check-in-approval`,
    `Participant check-in approve successfully`,
  )
}

export const cancelRegistration = async (body: any) => {
  const response = await axios.post(
    `${BASE_URL}v1/booking/cancel-user-all-booking`,
    body,
  )

  return response.data
}
export const deleteTicket = async (body: any) => {
  const response = await axios.post(
    `${BASE_URL}v1/booking/cancel-booking`,
    body,
  )

  return response.data
}

const getEventReviewsStats = async (params: { eventId: string }) => {
  const response = await axiosGet(`v1/reviews/stats`, params)
  return response.data
}

export const useGetEventReviewsStats = (params: { eventId: string }) => {
  const eventReviewsStat = useQuery<ReviewsStatsTypes>(
    ['event-review-stats'],
    () => getEventReviewsStats(params),
    { enabled: false },
  )
  return eventReviewsStat
}

const getEventReviewsList = async (params: { eventId: string }) => {
  const response = await axiosGet(`v1/reviews`, params)
  return response.data
}

export const useGetEventReviewsList = (params: { eventId: string }) => {
  const eventReviewsList = useQuery<any>(
    ['event-review-list'],
    () => getEventReviewsList(params),
    { enabled: false },
  )
  return eventReviewsList
}

const getEventForKids = async (
  kidId: string,
  params: { page: number; limit: number },
) => {
  const response = await axiosGet(`v1/kids/${kidId}/events`, params)
  return response.data
}

export const useGetEventForKids = (
  kidId: string,
  params: { page: number; limit: number },
) => {
  const kidEvents = useQuery<PaginatedEventsResp>(
    ['event-review-list'],
    () => getEventForKids(kidId, params),
    { enabled: false },
  )
  return kidEvents
}

export const getEventCategories = async () => {
  const response = await axiosGet(`v1/lists/event-categories`)
  return response.data
}
export const useGetEventCategories = () => {
  const eventCategoriesList = useQuery<CategoryType[]>(
    ['event-categories-list'],
    getEventCategories,
  )
  return eventCategoriesList
}
