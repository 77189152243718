import { useEffect } from 'react'
import OrganizationModal from '../../Modals/OrganizationModal'
import AppLayout from '../../../components/layout/layout'
import { useLocation, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Avatar,
  Button,
  Space,
  Tabs,
  Dropdown,
  Menu,
  Tooltip,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Events from './Events'
import Reviews from './Reviews'
import { connect, useDispatch } from 'react-redux'
import deleteSvg from '../../../assets/Images/delete_red.svg'
import pencilLine from '../../../assets/Images/PencilLine.svg'
import actions from '../../../Redux/Organization/action'
import Organizers from './Organizers'
import StatusDropdown from '../../../helper/StatusDropdown'
import OrganazationsDataType from '../types'
import OrganizationAnalyticsDataType from '../types'
import { getNameSignature } from 'helper/utility'
import FamiliesOrg from '../Profiles/Families'
import { useGetOrganizationDetails } from 'api/Organizations'
import OrganizationBasicInfo from '../../Organization/Profiles/BasicInfo/index'
import moment from 'moment'
import Inventory from './inventory/Inventory'
import Analytics from './Analytics/Analytics'
const { TabPane } = Tabs
interface propsType {
  organization: OrganazationsDataType
  organizationAnalytics: OrganizationAnalyticsDataType
  modalVisible: Boolean
  organizationReviews: any
}

const OrganizationProfile = ({
  // organization,
  organizationAnalytics,
  modalVisible,
  organizationReviews,
}: propsType) => {
  const dispatch = useDispatch()
  const location: any = useLocation()
  let { id }: any = useParams()
  const {
    data: organization,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrganizationDetails(id)

  useEffect(() => {
    refetch()
  }, [id])
  const actionMenu = (
    <Menu>
      <Menu.Item
        key="EditProfile"
        onClick={() => {
          dispatch({ type: actions.MODAL_VISIBLE, payload: true })
        }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          dispatch({
            type: actions.DELETE_ORGANIZATION_REQUEST,
            payload: organization.id,
          })
        }}
      >
        <Space className="text-color-red">
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <AppLayout id={id}>
      {organization ? (
        <div className="bg-grey">
          <div className="org-profile-header">
            <Row gutter={10}>
              <Col xl={{ span: 10 }}>
                <Row align="middle">
                  <Col span={24} lg={{ span: 24 }}>
                    <div className="d-flex align-center">
                      <Avatar src={organization?.photo?.post} size={80}>
                        {getNameSignature(organization?.name)}
                      </Avatar>
                      <div className="avatar-name align-self-center">
                        <Tooltip
                          placement="bottom"
                          title={
                            organization
                              ? organization?.name
                              : 'Organization Name'
                          }
                          color="blue"
                        >
                          <h1 style={{ marginBottom: '0px' }}>
                            <b className="b1">
                              {organization
                                ? organization?.name
                                : 'Organization Name'}
                            </b>
                          </h1>
                        </Tooltip>
                        <h4>
                          <b className="b3 text-secondary">
                            since .{' '}
                            {moment(organization.establishedOn).format(
                              'DD MMM, YYYY',
                            )}
                          </b>
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={{ span: 14 }}>
                <Row>
                  <Col span={24} className="Organization-header">
                    <Space>
                      <h5 className="status-title">
                        <b className="b1">Status</b>
                      </h5>
                      <div className="profile-head-action-dropdown">
                        <StatusDropdown
                          name="organization"
                          id={organization?.id}
                          status={
                            organization?.status === 'approved'
                              ? 'active'
                              : 'pending'
                          }
                        />
                      </div>
                    </Space>
                    <Dropdown overlay={actionMenu}>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className="actionButton actionButton-org-profile"
                        shape="round"
                      >
                        Manage
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Tabs
              defaultActiveKey="1"
              style={{ width: '100%', height: '100%' }}
              className="bg-white profile-tabs"
            >
              <TabPane tab="Basic Info" key="1" style={{ width: '100%' }}>
                <OrganizationBasicInfo
                  parent={organization}
                  analytics={organizationAnalytics}
                />
              </TabPane>
              <TabPane tab="Events" key="2">
                <Events />
              </TabPane>
              <TabPane tab="Organizers" key="3">
                <Organizers />
              </TabPane>
              <TabPane tab="Families" key="4">
                <FamiliesOrg />
              </TabPane>
              <TabPane tab="Inventory" key="5">
                <Inventory />
              </TabPane>
              <TabPane tab="Reviews" key="6">
                <Reviews reviews={organizationReviews} />
              </TabPane>
              <TabPane tab="analitycs" key="7">
                <Analytics />
              </TabPane>
            </Tabs>
          </Row>
        </div>
      ) : null}
      {modalVisible && (
        <OrganizationModal
          modalVisible={modalVisible}
          currentData={organization}
        />
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  organizationData: state.organization.organizationData,
  organization: state.organization.organization,
  organizationAnalytics: state.organization.organizationAnalytics,
  modalVisible: state.organization.isModalVisible,
  organizationReviews: state.organization.organizationReviews,
})
export default connect(mapStateToProps)(OrganizationProfile)
