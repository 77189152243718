import { axiosGet } from 'Redux/axiosHelper'
import { useQuery } from '@tanstack/react-query'
import { GetAllEventsParams, PaginatedEventsResp } from 'types/events'

// get events by different params
const getEvents = async (params: GetAllEventsParams) => {
  const response = await axiosGet(`v1/organization-events`, params)
  return response.data
}

export const useGetEvents = (params: GetAllEventsParams) => {
  const events = useQuery<PaginatedEventsResp>(
    ['all-events-by-params'],
    () => (params ? getEvents(params) : null),
    { enabled: false },
  )
  return events
}
