import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetEventForKids } from 'api/Events'
import Spinner from 'components/Spinner'
import { Empty, Pagination } from 'antd'
import usePagination from 'helper/usePagination'
import GlobalEventComponent from 'components/Events/Events'

const KidEvents = () => {
  const { id }: any = useParams()
  const [parms, setParms] = useState({
    page: 1,
    limit: 10,
  })
  const { isLoading, isFetching, data, refetch } = useGetEventForKids(id, parms)
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div>
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <GlobalEventComponent
          data={data}
          isLoading={isLoading}
          parms={parms}
          setParms={setParms}
        />
      )}
    </div>
  )
}

export default KidEvents
