import { useEffect, useState } from 'react'
import filterSvg from '../../../assets/Images/filter.svg'
import { Checkbox, Col, Row, Select } from 'antd'
import { Menu, Dropdown, Space } from 'antd'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'

const ActionHeader = ({ hidCols, setCols, search, sort, setParms }: any) => {
  const [visible, setVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hidCols,
      [e.target.name]: e.target.checked,
    }

    setCols(updatedHiddenColumns)
    localStorage.setItem('OrganizerCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('OrganizerCols')
    if (storedHiddenColumns) {
      setCols(JSON.parse(storedHiddenColumns))
    }
  }, [])
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const handleChangeSortName = (value: string) => {
    sort(value)
  }
  const menu2 = (
    <Menu
      // onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.name}
                  name="name"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Name</Col>
            </Row>
          ),
          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.organizations}
                  name="organizations"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Organization</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.phone}
                  name="phone"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Phone</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.email}
                  name="email"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Email</Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.status}
                  name="status"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Status</Col>
            </Row>
          ),
          key: '5',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: '6',
        },
      ]}
    />
  )
  const handleSearchChange = (e: any) => {
    // setSearchQuery(e)
    // search(searchQuery)
    setParms(prev => ({ ...prev, queryString: e }))
  }
  const debounced = debounce(handleSearchChange, 300)

  return (
    <div>
      <Space size={20} align="center" className="mentors-table-header">
        <Dropdown
          overlay={menu2}
          onOpenChange={handleVisibleChange}
          open={visible}
        >
          <h5 onClick={e => e.preventDefault()}>
            <Space>
              <img src={filterSvg} alt=""></img>
            </Space>
          </h5>
        </Dropdown>
        <Select
          className="custom-select-field"
          defaultValue="firstname"
          placeholder="sort by"
          allowClear
          onChange={handleChangeSortName}
        >
          <Select.Option value="firstname">Sort by: Name</Select.Option>
          <Select.Option value="email">Sort by: Email</Select.Option>
          <Select.Option value="-createdAt">Sort by: CreatedAt</Select.Option>
        </Select>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
      </Space>
    </div>
  )
}
export default ActionHeader
