import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import EventView from '../../../Organization/Profiles/events/EventViewOrg'

const MentorEvents = ({ eventsData, organizationData, mentorId }: any) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(eventsData)
  }, [eventsData])

  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        {/* <EventView
            mentorId={mentorId}
          data={data}
          setData={setData}
          eventsData={eventsData}
          organizationData={organizationData}
        /> */}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  eventsData: state.events.mentorEvents,
  modalVisible: state.events.ismodelVisible,
  organizationData: state.organization.organizationData,
})
export default connect(mapStateToProps)(MentorEvents)
