import React from 'react'
import EventView from './events/EventViewOrg'

const OrganizerEvents: React.FC = () => {
  return (
    <>
      <div className="bg-grey padding-top-10">
        <div className="profile_container">
          <EventView />
        </div>
      </div>
    </>
  )
}

export default OrganizerEvents
