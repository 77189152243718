import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import ColumnChart from 'components/global/charts/ColumnChart'
import info from 'assets/Images/Info.svg'
import Down from 'helper/Icons/DownIcon'
import { ComaprisionVals } from 'types/organization'

interface AgeGroupChartProps {
  data: any
}

const AgeGroupChart: React.FC<AgeGroupChartProps> = ({ data }) => {
  const [open, setOpen] = useState(0)
  const [selectedData, setSelectedData] = useState()
  const [current, setCurrent] = useState('All')
  const Age = [
    {
      key: '1',
      label: 'All',
    },
    {
      key: '2',
      label: 'Male',
    },
    {
      key: '3',
      label: 'Female',
    },
  ]
  const onClick = ({ key }: any) => {
    if (key === '1') {
      setSelectedData(
        data.kidsOveriew.all.map(item => {
          return {
            xField: item.age,
            yField: item.value,
            type: `${item.age} age`,
          }
        }),
      )
      setCurrent('All')
    } else if (key === '2') {
      setSelectedData(
        data.kidsOveriew.male.map(item => {
          return {
            xField: item.age,
            yField: item.value,
            type: `${item.age} age`,
          }
        }),
      )
      setCurrent('Male')
    } else if (key === '3') {
      setSelectedData(
        data.kidsOveriew.female.map(item => {
          return {
            xField: item.age,
            yField: item.value,
            type: `${item.age} age`,
          }
        }),
      )
      setCurrent('Female')
    }
    setOpen(0)
  }

  useEffect(() => {
    setSelectedData(
      data.kidsOveriew.all.map((item: ComaprisionVals) => {
        return {
          xField: item.age,
          yField: item.value,
          type: `${item.age} age`,
        }
      }),
    )
  }, [])

  return (
    <div className="border-1px radius-8 mt-30px p-15px">
      <div className="flex justify-between">
        <div>
          <h3 className="text-16">Age overview</h3>
          <div className="flex align-center ">
            <p className="grey-txt text-12 ">
              Monitoring the age of participants over a period of time
            </p>
            <img src={info} alt="info" className="pl-5px" />
          </div>
        </div>
        <Dropdown
          menu={{
            items: Age,
            onClick,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 1}
          overlayStyle={{ width: '200px' }}
        >
          <button
            className={` ${
              open === 1 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(1)}
          >
            Gender:{' ' + current} <Down />
          </button>
        </Dropdown>
      </div>
      <div className="mt-40px">
        {selectedData && (
          <ColumnChart
            chartData={selectedData}
            xAxisLabel="Age"
            yAxisLabel="Participants"
          />
        )}
      </div>
    </div>
  )
}

export default AgeGroupChart
