import React, { useState } from 'react'
import { Avatar } from 'antd'
import { NotesType } from '../types'
import { File, Photo } from 'types/globalTypes'
import { getNameSignature, capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'
interface NotesContentAreaPropsType {
  NoteData: NotesType
}
const ContentArea: React.FC<NotesContentAreaPropsType> = ({ NoteData }) => {
  const [isImagePreviewVisible, setImagePreviewVisible] =
    useState<boolean>(false)

  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const showImagePreview = (url: string) => {
    setImagePreviewVisible(true)
    setFileUrl(url)
  }
  const hideImagePreview = () => {
    setImagePreviewVisible(false)
  }
  return (
    <div
      className=" notes-bg  "
      style={{ height: 'fit-content', minHeight: '58vh' }}
    >
      <div
        className="px-20px py-20px flex justify-end flex-col h-full"
        style={{ overflowY: 'auto' }}
      >
        {NoteData ? (
          <div>
            <div className="flex  mt-20px">
              <Avatar
                size={42}
                // src={NoteData?.createdBy?.photo?.post}
                src={
                  NoteData?.createdByAdmin
                    ? NoteData?.createdByAdmin?.photo?.post
                    : NoteData?.createdBy?.photo?.post
                }
                alt="profile"
              >
                {/* {getNameSignature(NoteData?.createdBy?.firstName || 'Admin')} */}
                {getNameSignature(
                  NoteData?.createdByAdmin
                    ? NoteData?.createdByAdmin?.firstName
                    : NoteData?.createdBy?.firstName,
                )}
              </Avatar>
              <div className="flex flex-col pl-10px">
                <h4 className="text-14 w-600 flex pl-5">
                  {/* {NoteData?.createdBy?.firstName || 'Admin'} */}
                  {NoteData?.createdByAdmin
                    ? capitalizeFirstLetter(
                        NoteData?.createdByAdmin?.firstName,
                      ) +
                      ' ' +
                      capitalizeFirstLetter(NoteData?.createdByAdmin?.lastName)
                    : capitalizeFirstLetter(NoteData?.createdBy?.firstName) +
                      ' ' +
                      capitalizeFirstLetter(NoteData?.createdBy?.lastName)}
                  <span className="w-400 pl-5px">
                    created a {NoteData.type}
                  </span>
                </h4>
                <h4 className="text-12  pl-5">
                  {`${moment(NoteData?.createdAt).format(
                    'MMMM DD, YYYY',
                  )} at ${moment(NoteData?.createdAt).format('hh:mm A')}`}
                </h4>
              </div>
            </div>
            <div className="flex mt-20px">
              <div>
                <Avatar
                  size={42}
                  src={NoteData?.createdBy?.photo?.post}
                  alt="profile"
                >
                  {getNameSignature(
                    NoteData?.createdByAdmin
                      ? NoteData?.createdByAdmin?.firstName
                      : NoteData?.createdBy?.firstName,
                  )}
                </Avatar>
              </div>
              <div className="flex flex-col pl-10px">
                <h4 className="text-14 w-600 flex align-center">
                  {/* {NoteData?.createdBy?.firstName
                    ? capitalizeFirstLetter(NoteData.createdBy?.firstName)
                    : 'Admin'} */}
                  {NoteData?.createdByAdmin
                    ? capitalizeFirstLetter(
                        NoteData?.createdByAdmin?.firstName,
                      ) +
                      ' ' +
                      capitalizeFirstLetter(NoteData?.createdByAdmin?.lastName)
                    : capitalizeFirstLetter(NoteData?.createdBy?.firstName) +
                      ' ' +
                      capitalizeFirstLetter(NoteData?.createdBy?.lastName)}
                  <span className="w-500 text-10 pl-10px grey-txt">
                    {`${moment(NoteData?.createdAt).fromNow()}, ${moment(
                      NoteData?.createdAt,
                    ).format('hh:mm A')}`}
                  </span>
                </h4>
                <div className="notes-area">
                  {NoteData?.type !== 'incident' ? (
                    <span>{NoteData?.description}</span>
                  ) : (
                    <div>
                      <div>
                        <h1 className="title-14">Incident Name</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.name}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Date and Time of incident</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {`${moment(NoteData?.incident?.date).format(
                            'MMMM DD, YYYY',
                          )} at ${moment(NoteData?.incident?.time).format(
                            'hh:mm A',
                          )}`}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Event Name</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.eventOccurAt}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Incident type</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.type}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Parties Involved</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.partiesInvolved}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Description</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.description}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Location</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {`${NoteData?.incident?.address?.country}, ${NoteData?.incident?.address?.apartment}, ${NoteData?.incident?.address?.street}`}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Notified agencies</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.notifiedAgencies}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Notified agencies</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.notifiedAgencies}
                        </p>
                      </div>

                      <div>
                        <h1 className="title-14">Other Info</h1>
                        <p className="text-12 w-400 grey-txt ">
                          {NoteData?.incident?.otherInfo}
                        </p>
                      </div>

                      {NoteData?.incident?.mediaFilesIds.length > 0 && (
                        <div>
                          <h1 className="title-14">Related media</h1>

                          <div>
                            {NoteData?.incident?.media.map(
                              (media: File | Photo) => {
                                return 'post' in media ? (
                                  <img
                                    src={media?.post}
                                    alt={media?.name}
                                    width={240}
                                    height={160}
                                    onClick={() =>
                                      showImagePreview(media?.post)
                                    }
                                  />
                                ) : (
                                  <div>
                                    <object
                                      data={media?.link}
                                      type="application/pdf"
                                      height="100%"
                                      width="100%"
                                      className="cursor-pointer"
                                    >
                                      <p>
                                        Your browser does not support embedded
                                        PDFs.
                                      </p>
                                    </object>
                                    <a
                                      href={media?.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="cursor-pointer font12 b2 text-color-black"
                                    >
                                      <button className="prim-btn mt-10px">
                                        Download
                                      </button>
                                    </a>
                                  </div>
                                )
                              },
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center align-center addNote">
            <h1>No Note Selected</h1>
          </div>
        )}
      </div>

      {isImagePreviewVisible && (
        <div className={`image_preview_overlay`} onClick={hideImagePreview}>
          <div className={`image_preview_container`}>
            {fileUrl && (
              <img
                src={fileUrl}
                width={1200}
                height={1200}
                alt="img"
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default ContentArea
