import React from 'react'
import { Pie } from '@ant-design/charts'

interface PieChartDataProps {
  pieData: { type: string; value: number }[]
  color?: string[]
  name: string
}

const PieChart: React.FC<PieChartDataProps> = ({ pieData, color, name }) => {
  const config = {
    appendPadding: 10,
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    color: color,
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false as const,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        formatter: function formatter() {
          return `<h4 className="text-10px">${name}</h4>`
        },
      },
    },
  }

  return <Pie {...config} />
}

export default PieChart
