import { useQuery } from '@tanstack/react-query'
import GlobalEventComponent from '../../../../../components/Events/Events'
import { useState } from 'react'
import Spinner from '../../../../../components/Spinner'
import { getGuardianEvents } from '../../../../../api/Events'

const GuardianEvents = ({ id }: any) => {
  const [param, setParms] = useState({
    page: 1,
    limit: 10,
  })
  const { data, isLoading, isFetching } = useQuery(
    ['guardianEvent', param],
    () => getGuardianEvents(id, param),
  )

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <GlobalEventComponent
          data={data}
          isLoading={isLoading}
          parms={param}
          setParms={setParms}
        />
      )}
    </div>
  )
}
export default GuardianEvents
