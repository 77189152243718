import React, { useState, useEffect } from 'react'
import { getNotes } from 'api/notes'
import './styles/style.scss'
import { useQuery } from '@tanstack/react-query'
import { Row, Pagination } from 'antd'
import Spinner from 'components/Spinner'
import TopBar from './Components/TopBar'
import Recepient from './Components/Recepient'
import ContentArea from './Components/ContentArea'
import TaggedPeople from './Components/TaggedPeople'
import { connect } from 'react-redux'
import usePagination from 'helper/usePagination'
interface NotesPropsType {
  id: string
  familyId: string
  noteAdded?: boolean
}

const Notes: React.FC<NotesPropsType> = ({ id, familyId, noteAdded }) => {
  const [params, setParams] = useState({
    belongsToIds: id,
    page: 1,
    limit: 10,
  })

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ['getNotes'],
    queryFn: () => getNotes(params),
    enabled: false,
  })

  const [clickedNote, setClickedNote] = useState(
    data?.records?.length > 0 && data[0],
  )

  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams: setParams,
  })

  useEffect(() => {
    if (data?.records?.length > 0) {
      setClickedNote(data?.records[0])
    } else {
      setClickedNote(undefined)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [id, noteAdded, params])

  return (
    <>
      <div className="bg-grey  padding-top-10" style={{ height: '60vh' }}>
        <div className="bg-white" style={{ minHeight: '80vh' }}>
          {isLoading || isFetching ? (
            <div className="flex justify-center aligin-center">
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="border-btm">
                <TopBar id={id} familyId={familyId} />
              </div>
              <div className="grid-notes h-full">
                <div className="border-right ">
                  <Recepient
                    recepData={data?.records}
                    setNote={setClickedNote}
                  />
                </div>
                <div className="notes-bg">
                  <ContentArea NoteData={clickedNote} />
                </div>
                <div className="border-left">
                  <TaggedPeople TaggedData={clickedNote} />
                </div>
              </div>
            </div>
          )}
        </div>
        <Row className="justify-center flex mt-40px">
          <Pagination
            className="pb-20"
            style={{ width: '100%', display: 'flex' }}
            {...paginationInfo}
            onChange={PaginationNoTabel}
          />
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  noteAdded: state.family,
})
export default connect(mapStateToProps)(Notes)
