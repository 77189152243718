import React, { useEffect, useState } from 'react'
import TabHeader from './TabHeader'
import InventoryTable from './InventoryTable'
import { useParams } from 'react-router-dom'
import { useGetOrgInventoryList } from 'api/Inventory'
import Spinner from 'components/Spinner'

const Inventory: React.FC = () => {
  const { id }: { id: string } = useParams()
  const [params, setParams] = useState()

  const {
    data: inventoryData,
    isLoading: inventoryLoading,
    isFetching: inventoryFetching,
    refetch: inventoryRefetch,
  } = useGetOrgInventoryList(id)

  useEffect(() => {
    inventoryRefetch()
  }, [id])

  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        <TabHeader parms={params} setParms={setParams} />
        {inventoryLoading || inventoryFetching ? (
          <div className="flex align-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <InventoryTable dataset={inventoryData} />
        )}
      </div>
    </div>
  )
}

export default Inventory
