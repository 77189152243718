import EventView from './Views'
import Spinner from '../Spinner'
import { Select, Menu, Dropdown, Button, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import { useState } from 'react'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import EventFilters from './Header/Flters'
const { Option } = Select

const GlobalEventComponent = ({
  data,
  isLoading,
  parms,
  setParms,
  eventCategories,
}: any) => {
  const [view, setView] = useState('cardview')

  const onChangeCategory = (value: string) => {
    setParms({ ...parms, categoryIds: value })
  }

  const handleChangeStatus = (value: string) => {
    setParms({ ...parms, status: value })
  }

  const handleSearchChange = (e: any) => {
    setParms({ ...parms, queryString: e })
  }
  const debounced = debounce(handleSearchChange, 300)

  const menu = (
    <Menu onClick={e => setView(e.key)}>
      <Menu.Item key="cardview">Card view</Menu.Item>
      <Menu.Item key="calendar">Calendar view</Menu.Item>
      <Menu.Item key="tableview">Table view</Menu.Item>
    </Menu>
  )
  return (
    <div className="bg-grey  padding-top-10">
      <div className="bg-white p-30px h-full">
        <Col xl={{ span: 24 }}>
          <div className="flex-center">
            <EventFilters setParms={setParms} parms={parms} />

            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="nav-btn br-8">
                  {view} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
        </Col>
        {isLoading ? (
          <div className="bg-white flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <EventView
            data={data}
            parms={parms}
            setParms={setParms}
            view={view}
          />
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  eventCategories: state.lists.eventCategories,
})
export default connect(mapStateToProps)(GlobalEventComponent)
