import React from 'react'
import { Column } from '@ant-design/charts'

interface ColumnChartProps {
  chartData: { xField: number; yField: number; type: string }[]
  xAxisLabel: string
  yAxisLabel: string
}

const ColumnChart: React.FC<ColumnChartProps> = ({
  chartData,
  xAxisLabel,
  yAxisLabel,
}) => {
  const config = {
    data: chartData,
    columnStyle: {
      radius: [10, 10, 10, 10],
    },
    xField: 'xField',
    yField: 'yField',
    xAxis: {
      title: {
        text: xAxisLabel,
      },
      line: {
        style: {
          stroke: 'black',
        },
      },
      tickLine: {
        style: {
          stroke: 'black',
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
      line: {
        style: {
          stroke: 'black',
        },
      },
      tickLine: {
        style: {
          stroke: 'black',
        },
      },
    },
    label: {
      style: {
        fill: '#ABC5ED',
      },
    },
    interactions: [{ type: 'element-active' }],
    tooltip: {
      width: 800,
      fields: ['xField'],
      formatter: (value: any) => {
        return {
          name: 'Number of Participants',
          value: value.yField,
        }
      },
    },
  }

  return <Column {...config} />
}

export default ColumnChart
