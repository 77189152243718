import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import Filters from './Filters'
import { GetAllEventsParams } from 'types/events'
import '../evetnsCards/style.css'
import { useParams } from 'react-router-dom'
import { useGetEvents } from 'api/EventCreation/OrganizationEvents'
import Spinner from 'components/Spinner'
import EventCardView from 'components/global/views/EventCardView'

const EventView: React.FC = () => {
  const { id }: { id: string } = useParams()
  const [params, setParams] = useState<GetAllEventsParams>({
    page: 1,
    limit: 10,
    organizationId: id,
  })
  const { isLoading, data, isFetching, refetch } = useGetEvents(params)
  useEffect(() => {
    refetch()
  }, [params])
  const [view, setView] = useState('cardview')

  return (
    <div>
      <Row gutter={[10, 24]} align="middle">
        <Col xl={{ span: 24 }}>
          <Filters params={params} setParams={setParams} />
        </Col>
        {isLoading || isFetching ? (
          <div className="w-full flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <Col>
            <div>
              {view === 'cardview' && (
                <EventCardView
                  params={params}
                  setParams={setParams}
                  data={data.records}
                  totalCount={data.count}
                  hasNextPage={data.hasNextPage}
                  hasprevPage={data.hasPrevPage}
                />
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default EventView
