export const GroupNameCheckboxes = [
  { label: 'Bamrec inventory', value: 'Bamrec inventory' },
  { label: 'Group 1', value: 'Bamrec inventory' },
  { label: 'Group 2', value: 'Bamrec inventory' },
  { label: 'Group 3', value: 'Bamrec inventory' },
  { label: 'Group 4', value: 'Bamrec inventory' },
]

export const LocationsFilters = [
  { label: 'loc 1', value: 'loc 1' },
  { label: 'loc 2', value: 'loc 2' },
  { label: 'loc 3', value: 'loc 3' },
  { label: 'loc 4', value: 'loc 4' },
  { label: 'loc 5', value: 'loc 5' },
]
