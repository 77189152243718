import React, { useState } from 'react'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, Space } from 'antd'
import { capitalizeFirstLetter } from 'helper/utility'
import filterSvg from 'assets/Images/filter.svg'
import Filters from './Filters'

interface TabHeader {
  parms: any
  setParms: any
}

const TabHeader: React.FC<TabHeader> = ({ parms, setParms }) => {
  const [view, setView] = useState<'table' | 'card'>('table')
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <p onClick={() => setView('table')}>Table</p>,
    },
    {
      key: '2',
      label: <p onClick={() => setView('table')}>Card</p>,
    },
  ]

  return (
    <>
      <div className="flex align-center gap-20px">
        <div className="flex align-center gap-20px">
          <div onClick={e => e.preventDefault()}>
            <Space>
              <img src={filterSvg} alt="filtersvg" width={45} height={45}></img>
            </Space>
          </div>
          <div
            className="right-search-block w-100 d-inline-block"
            style={{ minWidth: '350px' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <div
                className={'search-input-box'}
                style={{ marginRight: '15px' }}
              >
                <input
                  type="search"
                  className="search-input-group"
                  placeholder="Search tools and equipment"
                  //   onChange={handleSearchChange}
                />
                <SearchOutlined />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
          <div className="flex align-center gap-20px">
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button className="nav-btn br-8">
                {capitalizeFirstLetter(view)} <DownOutlined />
              </Button>
            </Dropdown>
            <div>
              <Button className="nav-btn br-8">Add new</Button>
            </div>
          </div>
        </div>
      </div>

      <Filters parms={parms} setParms={setParms} />
    </>
  )
}

export default TabHeader
