import React, { useEffect, useState } from 'react'
import EventCardViewHeader from 'components/global/headers/EventCardViewHeader'
import { GetAllEventsParams } from 'types/events'
import { useParams } from 'react-router-dom'
import { useGetEvents } from 'api/EventCreation/OrganizationEvents'
import Spinner from 'components/Spinner'
import EventCardView from 'components/global/views/EventCardView'
import { useGetOrganizerEvents } from 'api/Organizers'

const OrganizerEvents: React.FC = () => {
  const { id }: { id: string } = useParams()

  const [params, setParams] = useState<GetAllEventsParams>({
    page: 1,
    limit: 10,
  })
  const [view, setView] = useState<'cardview' | 'calendar' | 'tableview'>(
    'cardview',
  )
  const { isLoading, data, isFetching, refetch } = useGetOrganizerEvents(
    id,
    params,
  )

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        <EventCardViewHeader
          view={view}
          setView={setView}
          params={params}
          setParams={setParams}
        />
        <div className="mt-20px">
          {isLoading || isFetching ? (
            <div className="w-full flex justify-center align-center">
              <Spinner />
            </div>
          ) : (
            <div>
              {view === 'cardview' && (
                <EventCardView
                  params={params}
                  setParams={setParams}
                  data={data.records}
                  totalCount={data.count}
                  hasNextPage={data.hasNextPage}
                  hasprevPage={data.hasPrevPage}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrganizerEvents
