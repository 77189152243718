import UserCirclePlus from 'assets/Images/UserCirclePlus.svg'
import CalendarBlank from 'assets/Images/CalendarBlank 2.svg'
import Group from 'assets/Images/Group.svg'
import Newspaper from 'assets/Images/Newspaper 2.svg'
import Users from 'assets/Images/Users 2.svg'
import UserCircle from 'assets/Images/UserCirclePlus N.svg'
import '../BasicInfo/statisticStyle.scss'

import { Col, Row } from 'antd'
import { useGetOrganizationAnalytics } from 'api/Organizations'
import { useEffect } from 'react'
import Spinner from 'components/Spinner'
const Statistic = ({ orgId }: { orgId: string }) => {
  const { data, isLoading, isFetching, refetch } =
    useGetOrganizationAnalytics(orgId)

  useEffect(() => {
    refetch()
  }, [orgId])

  return (
    <Col span={12}>
      <div className="profile_container">
        {isLoading || isFetching ? (
          <div className="flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex justify-between align-center">
              <h2 className="b1 h6">Statistic</h2>
            </div>
            <Row className="flex  mt-10px">
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={UserCircle} alt="UserCircle" />
                </h3>
                <p className="number">{data?.subscribedParents}</p>
                <p className="description">Subscribed parents</p>
              </div>
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={CalendarBlank} alt="CalendarBlank" />
                </h3>
                <p className="number">{data?.eventsPublished}</p>
                <p className="description">Event published</p>
              </div>
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={Users} alt="Users" />
                </h3>
                <p className="number">{data?.registeredParents}</p>
                <p className="description">Registered parents</p>
              </div>
            </Row>

            <Row className="flex  mt-10px">
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={Users} alt="Users" />
                </h3>
                <p className="number">{data?.followingParents}</p>
                <p className="description">Following parent</p>
              </div>
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={Group} alt="Group" />
                </h3>
                <p className="number">{data?.registeredKids}</p>
                <p className="description">Registered kids</p>
              </div>
              <div className="statistic-card shadow mt-10px">
                <h3>
                  <img src={Newspaper} alt="Newspaper" />
                </h3>
                <p className="number">{data?.newsPosted}</p>
                <p className="description">News posted</p>
              </div>
            </Row>
          </>
        )}
      </div>
    </Col>
  )
}
export default Statistic
