import React from 'react'
import { Empty, Pagination } from 'antd'
import { Row, Col } from 'antd'
import moment from 'moment'
import CustomCalender from 'helper/Calendar/Eventscalendar/CustomCalender'
// import EventCard from 'pages/Organization/Profiles/evetnsCards/EventCard'
import { EventResp, GetAllEventsParams } from 'types/events'
import { useGetEventCategories } from 'api/Events'
import usePagination from 'helper/usePagination'
import EventCard from 'components/Events/Views/CardView/SingleCard'

interface CardViewProps {
  params: GetAllEventsParams
  setParams: React.Dispatch<React.SetStateAction<GetAllEventsParams>>
  data: EventResp[]
  totalCount: number
  hasNextPage: boolean
  hasprevPage: boolean
}

const EventCardView: React.FC<CardViewProps> = ({
  params,
  setParams,
  data,
  totalCount,
  hasNextPage,
  hasprevPage,
}) => {
  const { data: categories } = useGetEventCategories()
  const [paginationInfo, PaginationNoTabel] = usePagination({
    totalCount: totalCount || 0,
    currentPage: params.page,
    setParams: setParams,
  })
  return (
    <Row gutter={[12, 24]}>
      <>
        <Col xs={{ span: 12 }} xl={{ span: 16 }}>
          <>
            {!data.length ? (
              <div className="w-full h-full flex justify-center align-center">
                <Empty />
              </div>
            ) : (
              data.map((event: EventResp) => {
                return (
                  <>
                    <EventCard event={event} />

                    {/* <EventCard
                      key={event._id}
                      eventImg={event.coverPhotos[0]?.post}
                      eventName={event.name}
                      eventLoc={event.location?.title}
                      eventStartDateMonth={moment(event.datesOfEvent[0]).format(
                        'MMM',
                      )}
                      eventStartDateDay={moment(event.datesOfEvent[0]).format(
                        'D',
                      )}
                      eventEndDateMonth={moment(event.datesOfEvent[1]).format(
                        'MMM',
                      )}
                      eventEndDateDay={moment(event.datesOfEvent[1]).format(
                        'D',
                      )}
                      eventId={event._id}
                      categoryImg={
                        categories?.find(
                          (cate: any) => cate._id === event.category._id,
                        )?.icon
                      }
                      seatsStatus={event.seatsStatus}
                      venueType={event.venueType}
                    /> */}
                  </>
                )
              })
            )}
            {(hasNextPage || hasprevPage) && (
              <Row className="justify-end mt-10">
                <Pagination
                  className="pb-20"
                  style={{ width: '100%', display: 'flex' }}
                  {...paginationInfo}
                  onChange={PaginationNoTabel}
                />
              </Row>
            )}
          </>
        </Col>
        <Col xl={{ span: 8 }} xs={{ span: 12 }}>
          <Row justify="center" className="justify-end">
            <Col lg={21} span={24}>
              <CustomCalender />
            </Col>
          </Row>
        </Col>
      </>
    </Row>
  )
}

export default EventCardView
