import { Avatar, Menu, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { history } from '../../../Redux/app-store'
import { capitalizeFirstLetter, getNameSignature } from 'helper/utility'
import type { ColumnsType, SorterResult } from 'antd/es/table/interface'
import OrganazationsDataType from 'pages/Organization/types'
import StatusDropdown from 'helper/StatusDropdown'
import moment from 'moment'
import editblackPencil from 'assets/Images/editblackPencil.svg'
import deleteBlackIcon from 'assets/Images/deleteBlackIcon.svg'
import { useGetOrganizerOrganizations } from 'api/Organizers'
import { useParams } from 'react-router-dom'
import Spinner from 'components/Spinner'

const menu = <Menu />

const Organizations: React.FC = () => {
  const { id }: { id: string } = useParams()
  const {
    data,
    isLoading,
    isFetching,
    refetch: orgOrganizerRefetch,
  } = useGetOrganizerOrganizations(id)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    organizationName: true,
    owner: true,
    referralType: true,
    dateApplied: true,
    status: true,
    action: true,
  })

  const columns: ColumnsType<OrganazationsDataType> = [
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
      render: (text, organizations, record: any) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push({
              pathname: `/organization-profile/${organizations?.key}`,
              state: organizations?.key,
            })
          }}
        >
          <Space size={8}>
            <Avatar size={24} src={organizations?.organizationAvatar}>
              {getNameSignature(text)}
            </Avatar>
            {text}
          </Space>
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.organizationName?.length - b.organizationName?.length,
      sortOrder:
        sortedInfo.columnKey === 'organizationName' ? sortedInfo.order : null,

      className: hiddenColumns.organizationName === false ? 'display-none' : '',
    },

    // {
    //   title: 'Admin Name',
    //   dataIndex: 'owner',
    //   key: 'owner',
    //   render: (text, record: any, index) => (
    //     <Space size={8}>
    //       <Avatar size={24} src={record.ownerAvatar}></Avatar>
    //       {text}
    //     </Space>
    //   ),
    //   sorter: (a: any, b: any) => a.owner?.length - b.owner?.length,
    //   sortOrder: sortedInfo.columnKey === 'owner' ? sortedInfo.order : null,
    //   className: hiddenColumns.owner === false ? 'display-none' : '',
    // },
    // {
    //   title: 'Date CreatedAt',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   sorter: (a: any, b: any) => a.createdAt?.length - b.createdAt?.length,
    //   sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
    //   className: hiddenColumns.createdAt === false ? 'display-none' : '',
    // },
    {
      title: 'Access Level',
      dataIndex: 'accessLevel',
      key: 'accessLevel',
      width: '350px',
      className: hiddenColumns.status === false ? 'display-none' : '',
      render: (status, record: any, title) => (
        // <StatusDropdown
        //   id={record?.key}
        //   name="organization"
        //   status={status}
        //   changeFn={changeStatus}
        //   // refetchData={refetchOrg}
        // />
        <div
          className="flex align-center justify-center gap-10px"
          style={{
            width: '200px',
            height: '40px',
            border: '1px solid #ebedf1',
            borderRadius: '12px',
          }}
        >
          <div
            style={{
              backgroundColor: `${status === 'active' ? '#A6CA16' : '#FF6961'}`,
              width: '12px',
              height: '12px',
              borderRadius: '50%',
            }}
          ></div>
          <p className="text-12px">{capitalizeFirstLetter(status)}</p>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  useEffect(() => {
    orgOrganizerRefetch()
  }, [id])
  const [organizationData, setOrganizationData] = useState()

  useEffect(() => {
    setOrganizationData(
      data?.map(item => {
        return {
          key: item.organization.id,
          organizationName: item.organization.name,
          organizationAvatar: item.organization.photo?.post,
          accessLevel: item.accessLevel,
          action: <div className="prim-btn">View</div>,
        }
      }),
    )
  }, [data])

  return (
    <div className="bg-grey container" style={{ paddingTop: '10px' }}>
      <div className="orgnz-profile-tab-organizations-bg pagination">
        {isLoading || isFetching ? (
          <div className="w-full flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Table
            className="colored_header_table"
            dataSource={organizationData}
            columns={columns}
            pagination={false}
            scroll={{ x: 400 }}
          />
        )}
      </div>
    </div>
  )
}

export default Organizations
