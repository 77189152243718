import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
} from 'antd'
import FileUploader from 'helper/FileUploader'
import RelationsList from 'helper/RelationsList'
import PhoneInput from 'react-phone-input-2'
import MapModal from 'helper/Map/MapModal'
import AllergyList from 'helper/AllergyList'
import { adultStore } from 'state/family/adult'
import GenderSelect from 'helper/GenderSelect'
import { Address, Phone } from 'types/globalTypes'
import {
  CreateAdultPayload,
  FamilyMemberAdult,
  UpdateAdultPayload,
} from 'types/family/adult'
import moment from 'moment'
import { validatePhone, validateAlphabets } from 'helper/utility'
import { useCreateAdult, useUpdateAdult } from 'api/family/adult'
import { myStore } from 'state/store'

interface AdultFormProps {
  form: FormInstance
  familyId: string
}

interface AdultStoreStateTypes {
  foundEmail: string
  adultData: FamilyMemberAdult
  setAdultData: (state: FamilyMemberAdult) => void
  setAdultCurrentStep: (state: number) => void
}

const AdultForm: React.FC<AdultFormProps> = ({ form, familyId }) => {
  const { mutate: createAdultMutation, isLoading: createAdultMutationLoading } =
    useCreateAdult()
  const { mutate: updateAdultMutation, isLoading: updateAdultMutationLoading } =
    useUpdateAdult()
  const {
    foundEmail,
    adultData,
    setAdultData,
    setAdultCurrentStep,
  }: AdultStoreStateTypes = adultStore(store => ({
    foundEmail: store.findEmail,
    adultData: store.adultData,
    setAdultData: store.setAdultData,
    setAdultCurrentStep: store.setAdultCurrentStep,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [isEmergencyContact, setIsEmergencyContact] = useState<boolean>(
    adultData ? adultData.isEmergencyContact : false,
  )
  const [avatar, setAvatar] = useState<string>('')
  const [phone, setPhone] = useState<Phone>({
    dialCode:
      adultData && adultData.phone?.dialCode ? adultData.phone.dialCode : '',
    isoCode:
      adultData && adultData.phone?.isoCode ? adultData.phone.isoCode : '',
    number: adultData && adultData.phone?.number ? adultData.phone.number : '',
  })
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<Address>(
    adultData && adultData?.address,
  )
  const [allergies, setAllergies] = useState([])

  const formSchema = {
    photoId: adultData ? adultData?.photoId : undefined,
    firstName: adultData ? adultData.firstName : undefined,
    middleName: adultData ? adultData?.middleName : undefined,
    nickName: adultData ? adultData?.nickName : undefined,
    lastName: adultData ? adultData.lastName : undefined,
    dob: adultData && adultData?.dob ? moment(adultData.dob) : undefined,
    phone:
      adultData && adultData.phone?.number ? adultData.phone.number : undefined,
    gender: adultData ? adultData?.gender : undefined,
    email: adultData ? adultData.email : foundEmail,
    isEmergencyContact: adultData ? adultData.isEmergencyContact : false,
    allergies: adultData ? adultData.allergies : [],
    medicalNotes: adultData ? adultData?.medicalNotes : undefined,
    relationId: adultData ? adultData.relation._id : undefined,
  }

  const onFinish = (values: CreateAdultPayload) => {
    const payload: CreateAdultPayload = { ...values }
    payload.address = mapModalData ? mapModalData : undefined
    payload.phone = phone.number && phone.number.length > 4 ? phone : undefined
    payload.dob = values.dob
      ? moment(values.dob).format('YYYY-MM-DD')
      : undefined

    if (!adultData) {
      createAdultMutation(
        { familyId, payload },
        {
          onSuccess: data => {
            setAdultData(data)
            setAdultCurrentStep(1)
          },
        },
      )
    } else {
      let deletedObject = {}
      for (const key in payload) {
        if (payload[key] === undefined) {
          deletedObject = { ...deletedObject, [key]: 1 }
        }
      }
      updateAdultMutation(
        {
          adultId: adultData._id,
          payload: {
            ...payload,
            deleteObject: deletedObject,
          } as UpdateAdultPayload,
        },
        {
          onSuccess: data => {
            setAdultData(data)
            setAdultCurrentStep(1)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(createAdultMutationLoading || updateAdultMutationLoading)
  }, [createAdultMutationLoading || updateAdultMutationLoading])

  return (
    <div className="mt-20px">
      <Form
        name="adult-create-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={
                  adultData && adultData?.photoId
                    ? adultData.photoId
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <p className="text-12 w-400">
          Adults who are allowed to pick up child(ren).
        </p>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">FirstName</label>
            <Form.Item
              colon={false}
              name="firstName"
              rules={[
                { required: true, message: 'First Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter First Name" required name="firstname" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Middle Name</label>
            <Form.Item colon={false} name="middleName">
              <Input placeholder="Enter Middle Name" name="middleName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <label className="input-label required-input">Last Name</label>
            <Form.Item
              colon={false}
              name="lastName"
              rules={[
                { required: true, message: 'Last Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter Last Name" required name="lastName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Preferred Name</label>
            <Form.Item colon={false} name="nickName">
              <Input placeholder="Enter Preferred Name" name="nickName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label required-input">Relationship</label>
            <Form.Item
              className="form-control"
              name="relationId"
              rules={[{ required: true, message: 'Relationship is required' }]}
            >
              <RelationsList
                form={form}
                relationId={formSchema.relationId}
                data={
                  adultData && adultData.relation
                    ? adultData.relation.name
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Gender</label>
            <Form.Item name="gender">
              <GenderSelect
                form={form}
                data={
                  adultData && adultData.gender ? adultData.gender : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Date of Birth</label>
            <Form.Item name="dob" required>
              <DatePicker name="dob" className="selector w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="input-label ">Email</label>
            <Form.Item name="email">
              <Input placeholder="example@gmail.com" name="email" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[{ validator: validatePhone }]}
            >
              <PhoneInput
                country={phone?.isoCode || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Home address</label>
            <Form.Item>
              <Input
                placeholder="Location"
                onClick={() => {
                  setMapModalVisible(true)
                }}
                value={
                  mapModalData
                    ? mapModalData.city + ',' + mapModalData.state
                    : ''
                }
              />
              {mapModalVisible && (
                <MapModal
                  setMapModalVisible={setMapModalVisible}
                  setMapModalData={setMapModalData}
                  mapData={mapModalData}
                />
              )}{' '}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label ">Allergies</label>
            <Form.Item
              name="allergies"
              className="form-control allergies-field "
            >
              <AllergyList
                form={form}
                setAllergies={setAllergies}
                data={
                  adultData && adultData.allergies ? adultData.allergies : []
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Medical Notes</label>
            <Form.Item name="medicalNotes">
              <Input
                placeholder="Ex.: Asthma, Lactose intolerant"
                name="medicalNotes"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={0} className="modal-checker">
          <Col span={24} className="mb-16">
            <Form.Item name="isEmergencyContact" valuePropName="checked">
              <div className="d-flex align-items-center checkbox-container">
                <Checkbox
                  name="isEmergencyContact checkbox-style"
                  checked={isEmergencyContact}
                  onChange={e => setIsEmergencyContact(e.target.checked)}
                >
                  <div className="w-600 text-12 mt-8px">Emergency Contact</div>
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AdultForm
