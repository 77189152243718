import React from 'react'
import info from 'assets/Images/Info.svg'

interface TotalOverviewProps {
  label: string
  amount: number
}

const TotalOverview: React.FC<TotalOverviewProps> = ({ label, amount }) => {
  return (
    <div className="border-1px radius-6 px-15px py-12px ">
      <div className="flex justify-between align-center">
        <h1 className="text-16">{label}</h1>
        <img src={info} alt="info" />
      </div>
      <div className="mt-30px flex justify-between align-center">
        <h1 className="text-40 w-600">{amount}</h1>
      </div>
    </div>
  )
}

export default TotalOverview
