/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Row, Col, Button, Tooltip, Dropdown, Menu, Space } from 'antd'
import { useDispatch } from 'react-redux'
import AppLayout from '../../components/layout/layout'
import { Tabs } from 'antd'
import actions from '../../Redux/Organization/action'
import printerImg from '../../assets/Images/Printer.svg'
import downloadImg from '../../assets/Images/Download.svg'
import statusimg from '../../assets/Images/Activity.svg'
import deleteImg from '../../assets/Images/delete_white.svg'
import Statusmodal from '../../helper/Statusmodal'
import OrganazationsDataType from './types'
import OrganizationTable from './Table/OrganizationTable'
import OrganizationalModal from '../../components/modals/organizationModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { changeOrganizationStatus, getOrganizations } from 'api/Organizations'
import { GetRequestParams, RequestParms } from 'types/globalTypes'
import Spinner from 'components/Spinner'
import { forceRefetch } from 'helper/utility'
import { DownOutlined } from '@ant-design/icons'
import ExportOrganization from './ExportOrganization'
import FilePlus from 'assets/Images/FilePlus.svg'
import ImportOrganization from './ImportOrganization'
const { TabPane } = Tabs
const Oraganazation = () => {
  const [activeData, setActiveData] = useState<OrganazationsDataType[]>([])
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [someState, setSomeState] = useState(1)
  const dispatch = useDispatch()
  const onChangeSomeState = (newSomeState: any) => {
    setSomeState(newSomeState)
  }
  const [parms, setParams] = useState<RequestParms & GetRequestParams>({
    page: 1,
    limit: 10,
    status: 'approved',
    sort: '-createdAt',
  })

  const { data, isLoading, refetch, isFetching } = useQuery(
    ['getOrganization'],
    {
      queryFn: () => getOrganizations(parms),
      enabled: false,
    },
  )
  useEffect(() => {
    refetch()
  }, [parms])
  const queryClient = useQueryClient()
  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await changeOrganizationStatus(params, payload)
    },
    onSuccess: () => {
      forceRefetch('getOrganization', queryClient)
      refetch()
    },
  })
  const deleteOrganization: any = (activeItem: any) => {
    dispatch({
      type: actions.DELETE_ORGANIZATION_DATA,
      payload: activeItem.id,
    })
  }
  const [alertModalVisible, setAlertModalVisible] = useState(false)

  const deleteMultipleRecords = () => {
    let _data = [...activeData]
    selectedRows.forEach((row: any) => {
      _data = _data.filter(t => t.id !== row.key)
    })
    setActiveData(_data)
    dispatch({
      type: actions.ADD_ORGANIZATION_DATA,
      payload: _data,
    })
  }
  const alertModalData = {
    heading: 'Status changed',
    message1: 'Save',
  }

  const handleImportClick = () => {
    setIsImportModalVisible(true)
  }

  // calling the correct method based on the condition
  function TabCallback(key: any) {
    if (key == 1) {
      setParams({ ...parms, page: 1, status: 'approved' })
    } else if (key == 2) {
      setParams({ ...parms, page: 1, status: 'under_review' })
    }
  }

  // const csvData = data?.records?.map((item: OrganazationsDataType) => {
  //   return {
  //     key: item.id,
  //     organizationName: item.name,
  //     adminName: item.owner?.firstName,
  //     EstablishedDate: item.establishedOn,
  //     status: item.status,
  //   }
  // })
  const headers = [
    { label: 'key', key: 'key' },
    { label: 'Organization Name', key: 'organizationName' },
    { label: 'Admin Name', key: 'adminName' },
    { label: 'Refferal type', key: 'referralType' },
    { label: 'Date applied  ', key: 'EstablishedDate' },
    { label: 'Status', key: 'status' },
  ]
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const handleImportModalClose = () => {
    setIsImportModalVisible(false)
  }
  const [newOrganization, setnewOrg] = useState(false)
  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
        <Space className="mt-10" onClick={handleImportClick}>
          <img alt="importIcon" src={FilePlus} /> Import
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <AppLayout>
      <Row className="page-header" justify="space-between">
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <h1 className="table-head mb-0">Organizations</h1>
        </Col>
        <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
          {selectedRows?.length > 0 ? (
            <Space
              className="organizations-table-onselect-btn"
              size={10}
              align="end"
            >
              <Tooltip placement="bottom" title="Status" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  icon={
                    <img
                      alt="img"
                      src={statusimg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                  onClick={() => {
                    setAlertModalVisible(true)
                  }}
                >
                  Status
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Print" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  onClick={() => {
                    window.print()
                  }}
                  icon={
                    <img
                      alt="img"
                      src={printerImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Print
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Export" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  icon={<img alt="img" src={downloadImg}></img>}
                >
                  {/* <CSVLink
                    data={csvData}
                    headers={headers}
                    style={{
                      color: 'black',
                      padding: '1%',
                      height: '20px',
                      marginRight: '10px',
                    }}
                    filename="OraganizationsData.csv"
                    target="_blank"
                  >
                    Export
                  </CSVLink> */}
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  onClick={() => {
                    deleteMultipleRecords()
                  }}
                  icon={
                    <img
                      alt="img"
                      src={deleteImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Delete
                </Button>
              </Tooltip>
            </Space>
          ) : (
            <div className="right-search-block">
              <div
                className="table-action-btn new-organizations-export-btn"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {localStorage.getItem('role') === 'admin' && (
                  <Dropdown
                    overlay={menu}
                    placement="bottom"
                    className="bg-grey mx-20px"
                  >
                    <button className=" prim-sm-btn" style={{ color: 'black' }}>
                      More <DownOutlined />
                    </button>
                  </Dropdown>
                )}

                <Tooltip placement="bottom" title="Create">
                  <Button
                    className={'btn-new primary br-8'}
                    onClick={() => {
                      setnewOrg(true)
                    }}
                  >
                    New organization
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Tabs
            className="orgn-tabs"
            defaultActiveKey="1"
            onChange={TabCallback}
          >
            <TabPane tab="Active" key="1">
              <Row>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <OrganizationTable
                      activeData={data}
                      parms={parms}
                      setParms={setParams}
                      setEditModalVisible={setEditModalVisible}
                      setSelectedRows={setSelectedRows}
                      someState={someState}
                      onChangeSomeState={onChangeSomeState}
                      status="approved"
                      changeStatus={changeMutation}
                      refetchOrg={refetch}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Pending" key="2">
              <Row style={{ width: '100%' }}>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading || isFetching ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <OrganizationTable
                      activeData={data}
                      setParms={setParams}
                      parms={parms}
                      setEditModalVisible={setEditModalVisible}
                      setSelectedRows={setSelectedRows}
                      someState={someState}
                      onChangeSomeState={onChangeSomeState}
                      status="under_review"
                      changeStatus={changeMutation}
                      refetchOrg={refetch}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {alertModalVisible && (
        <Statusmodal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModalVisible}
          data={alertModalData}
          name="organization"
          selectedRows={selectedRows}
        ></Statusmodal>
      )}
      {newOrganization && (
        <OrganizationalModal visible={newOrganization} setVisblie={setnewOrg} />
      )}
      <ExportOrganization
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={data?.records}
      />
      {isImportModalVisible && (
        <ImportOrganization
          visible={isImportModalVisible}
          onClose={handleImportModalClose}
        />
      )}
    </AppLayout>
  )
}

export default Oraganazation
