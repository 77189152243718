import axios from 'axios'
import { axiosGet } from '../Redux/axiosHelper'
import { RequestParms } from '../types/globalTypes'
import { BASE_URL, failureMessage, successMessage } from './global'
import { GetAllEventsParams, PaginatedEventsResp } from 'types/events'
import { useQuery } from '@tanstack/react-query'

export const updateOrganizerStatus = async (
  params: string,
  payload: string,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/organizers/${params}/status`,
    { status: payload },
  )
  response.status === 200
    ? successMessage('Status updated Successfully !')
    : failureMessage('Error while updating Status')
}
export const deleteOrganizer = async (id: string) => {
  const response = await axios.delete(`${BASE_URL}v1/organizers/${id}`)
  response.status === 200
    ? successMessage('Organizer Deleted Successfully !')
    : failureMessage('Error while Deleting Organizer')
}
export const confirmOrganizerImportData = async (payload: any) => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/organizer`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const importOrganizerFile = async (payload: any) => {
  // const response = await axios.post(`${BASE_URL}v1/imports/organizer`, payload)
  // console.log(response)
  //  if(response.status === 200){
  //   return response.data
  //  }else{
  //   console.log(response?.data)
  //   failureMessage(`Error:${response.statusText}`)
  //   failureMessage(`Error:${response.status}`)

  try {
    const response = await axios.post(
      `${BASE_URL}v1/imports/organizer`,
      payload,
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const confirmEmail = async (organizerId: string, payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/change-email/${organizerId}`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email change Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const blockEmail = async (payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/block-email`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email block Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}

// get all organizers
export const getOrganizers = async (parms: any) => {
  const response = await axiosGet('v1/admins/organizers', parms)
  return response.data
}

export const useGetOrganizers = (params: any) => {
  const organizerData = useQuery<any>(
    ['getOrganizers'],
    () => getOrganizers(params),
    { enabled: false },
  )
  return organizerData
}

// get organizer events
const getOrganizerEvents = async (
  organizerId: string,
  params: GetAllEventsParams,
) => {
  const response = await axiosGet(`v1/organizers/events/${organizerId}`, params)
  return response.data
}

export const useGetOrganizerEvents = (
  organizerId: string,
  params: GetAllEventsParams,
) => {
  const organizerEvents = useQuery<PaginatedEventsResp>(
    ['get-organizer-events'],
    () => (organizerId ? getOrganizerEvents(organizerId, params) : null),
    { enabled: false },
  )
  return organizerEvents
}

// get organizer organizations
const getOrganizerOrganizations = async (organizerId: string) => {
  const response = await axiosGet(`v1/organizers/${organizerId}/organizations`)
  return response.data
}
export const useGetOrganizerOrganizations = (organizerId: string) => {
  const organizerOrganizations = useQuery<any>(
    ['organizations-by-orgId'],
    () => (organizerId ? getOrganizerOrganizations(organizerId) : null),
    {
      enabled: false,
    },
  )
  return organizerOrganizations
}
