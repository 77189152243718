import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Form, Input, Radio } from 'antd'
import { ArrowUp } from '@phosphor-icons/react'
import Down from 'helper/Icons/DownIcon'
import { SortByCheckBoxes } from 'components/Events/Header/filterArrays'
import { GroupNameCheckboxes, LocationsFilters } from './FiltersConstants'

interface FiltersProps {
  parms: any
  setParms: any
}

const Filters: React.FC<FiltersProps> = ({ parms, setParms }) => {
  const [sortBy, setSortBy] = useState('')
  const [timeStatus, setTimeStatus] = useState('')
  const [orderBy, setOrderBy] = useState('desc')
  const [open, setOpen] = useState<number>(0)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const clear = (item: string, setFn?: any) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const GroupNameFilter = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={GroupNameCheckboxes}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          value={timeStatus}
          onChange={(val: any) => setTimeStatus(val.target.value)}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('timeStatus', setTimeStatus)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)

              timeStatus &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  timeStatus: timeStatus,
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]

  const locationFilter = [
    {
      key: 'search',
      label: (
        <div>
          <div className="search_field">
            <Form.Item className={`custom-select-form mb-0`} name="searchQuery">
              <Input
                placeholder="Search by organizations"
                className={`py-12 br-12`}
                onChange={e => {
                  e.preventDefault()
                  //   debounced(e.target.value);
                }}
              ></Input>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      key: 'orgs',
      label: (
        <Radio.Group
          options={LocationsFilters}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          value={timeStatus}
          onChange={(val: any) => setTimeStatus(val.target.value)}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-16">
          <button
            className={'clr-btn'}
            onClick={() => {
              clear('organizationIds')
              setOpen(0)
            }}
          >
            Clear
          </button>
          <button className="aply-btn">Apply</button>
        </div>
      ),
    },
  ]

  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={SortByCheckBoxes}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={(val: any) => setSortBy(val.target.value)}
          />
          <div className=" border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600 ">Sort Results</h2>
              <Radio.Group
                onChange={(val: any) => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                value={orderBy}
              >
                <Radio value={'asc'}>Ascending</Radio>
                <Radio value={'desc'}>Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('orderBy', setSortBy)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sortBy && orderBy
                ? setParms((prevParms: any) => ({
                    ...prevParms,
                    sortBy: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : orderBy
                ? setParms((prevParms: any) => ({
                    ...prevParms,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : setParms((prevParms: any) => ({
                    ...prevParms,
                    sortBy: sortBy,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  return (
    <div className="mt-20px flex justify-between align-center flex-wrap">
      <div
        className="flex align-center flex-wrap mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Filter by:</h2>
        <Dropdown
          menu={{
            items: GroupNameFilter,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 1}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 1 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(1)}
          >
            Group name <Down />
          </button>
        </Dropdown>

        <Dropdown
          menu={{
            items: locationFilter,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 2}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(2)}
          >
            Location <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: SortBy,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click ' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(5)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            Creation Date
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}

export default Filters
