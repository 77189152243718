import React, { useEffect, useState } from 'react'
import { useGetOrganizationAnalytics } from 'api/Organizations'
import { useParams } from 'react-router-dom'
import info from 'assets/Images/Info.svg'
import PieChart from 'components/global/charts/PieChart'
import AnalyticsHeader from 'components/global/headers/AnalyticsHeader'
import TotalOverview from './TotalOverview'
import Spinner from 'components/Spinner'
import ColumnChart from 'components/global/charts/ColumnChart'
import AgeGroupChart from './AgeGroupChart'

const Analytics = () => {
  const { id }: { id: string } = useParams()
  const [params, setParams] = useState()
  const { data, isLoading, isFetching, refetch } =
    useGetOrganizationAnalytics(id)

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        <AnalyticsHeader setParams={setParams} />

        <div>
          {isLoading || isFetching ? (
            <Spinner />
          ) : (
            <>
              <div className="grid grid-50 mt-15px">
                <TotalOverview
                  label="Total events"
                  amount={data.eventsPublished}
                />
                <TotalOverview
                  label="Total revenue"
                  amount={data.totalRevenue}
                />
              </div>

              <div className="grid grid-50">
                <div className="border-1px radius-8 mt-30px p-15px">
                  <div>
                    <div className="flex align-center ">
                      <h3 className="text-16">
                        Distribution of revenue per Event
                      </h3>
                      <img src={info} alt="info" className="pl-5px" />
                    </div>
                  </div>
                  {data?.revenue ? (
                    <div className="grid grid-50">
                      <div className="border-1px radius-6 mt-20px p-30px text-center flex flex-col justify-center">
                        {data.revenue?.map((item: any) => {
                          return (
                            <div className="flex align-center justify-between">
                              <div className="flex align-center">
                                <div
                                  style={{ backgroundColor: `${item.color}` }}
                                  className="w-10px h-10px radius-12"
                                ></div>
                                <div className="flex-center">
                                  <h4 className="text-14">{item?.title}</h4>
                                </div>
                              </div>
                              <h4 className="w-600 text-14">${item.amount}</h4>
                            </div>
                          )
                        })}
                      </div>
                      <div className="h-14">
                        <PieChart
                          pieData={data?.revenue.map(revenue => {
                            return {
                              type: revenue.title,
                              value: revenue.amount,
                            }
                          })}
                          color={data?.revenue.map(revenue => revenue.color)}
                          name="Revenue"
                        />
                      </div>
                    </div>
                  ) : (
                    <h4>There's not much here Check back later.</h4>
                  )}
                </div>
                <div className="border-1px radius-8 mt-30px p-15px">
                  <div>
                    <div className="flex align-center ">
                      <h3 className="text-16">Participants gender</h3>

                      <img src={info} alt="info" className="pl-5px" />
                    </div>
                  </div>
                  {data?.kidsOveriew?.genderCount ? (
                    <div className="grid grid-50">
                      <div className="border-1px radius-6 mt-20px p-30px text-center flex flex-col justify-center">
                        <div className="flex align-center ">
                          <div
                            style={{ backgroundColor: '#2459A8' }}
                            className="w-15px h-15px radius-12 mr-10px"
                          ></div>
                          <h4>Male</h4>
                        </div>
                        <div className="flex align-center mt-10px">
                          <div
                            style={{ backgroundColor: '#ABC5ED' }}
                            className="w-15px h-15px radius-12 mr-10px"
                          ></div>
                          <h4>Female</h4>
                        </div>
                      </div>

                      <div className="h-14">
                        <PieChart
                          pieData={[
                            {
                              type: 'Male',
                              value: data?.kidsOveriew?.genderCount.male,
                            },
                            {
                              type: 'Female',
                              value: data?.kidsOveriew?.genderCount.female,
                            },
                          ]}
                          color={['#2459A8', '#ABC5ED']}
                          name="Gender"
                        />
                      </div>
                    </div>
                  ) : (
                    <h4>There's not much here Check back later.</h4>
                  )}
                </div>
              </div>

              <div>
                <AgeGroupChart data={data} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Analytics
