import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import AppLayout from '../../components/layout/layout'
import { Tabs } from 'antd'
import actions from '../../Redux/Organizer/action'

import Statusmodal from '../../helper/Statusmodal'
import OrganizerTable from './Table/OrganizerTable'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getOrganizers,
  updateOrganizerStatus,
  useGetOrganizers,
} from '../../api/Organizers'
import { GetRequestParams, RequestParms } from '../../types/globalTypes'
import ActionHeaders from './Headers/ActionHeader'
import Spinner from '../../components/Spinner'
import PageNameHeader from './Headers/PageNameHeader'
import CreateOrganizerModal from '../../components/modals/organizerModal'
import { deleteOrganizer } from '../../api/Organizers'
import { forceRefetch } from '../../helper/utility'
const { TabPane } = Tabs

const Organizer = () => {
  const [editorgData, setEditorgData] = useState<any>(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [showOrg, setShowOrg] = useState(false)
  const [parms, setParms] = useState<any>({
    page: 1,
    status: 'active',
  })
  // const { isLoading, data, refetch } = useQuery(['getOrganizers', parms], () =>
  //   getOrganizers(parms),
  // )
  const { isLoading, data, refetch, isFetching } = useGetOrganizers(parms)
  const [activeData, setActiveData] = useState<any[]>([])
  const queryClient = useQueryClient()

  const dispatch = useDispatch()

  function callback(key: any) {
    if (key == 1) {
      setParms({ ...parms, status: 'active', page: 1 })
    } else if (key == 2) {
      setParms({ ...parms, status: 'inactive', page: 1 })
    }
  }
  useEffect(() => {
    refetch()
  }, [parms])
  const deleteMutation = useMutation({
    mutationFn: async ({ id }: any) => {
      return await deleteOrganizer(id)
    },
  })
  const deleteOrganizars = (id: any) => {
    deleteMutation.mutate({ id })
    refetch({ stale: false })
  }
  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const deleteMultipleRecords = () => {
    let _data = [...activeData]
    selectedRows.forEach((row: any) => {
      _data = _data.filter(t => t.id !== row.key)
    })
    setActiveData(_data)
    dispatch({
      type: actions.ADD_ORGANIZER,
      payload: _data,
    })
  }
  const alertModalData = {
    heading: 'Status changed',
    message1: 'Save',
  }

  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await updateOrganizerStatus(params, payload)
    },
    onSuccess: () => {
      forceRefetch('getOrganizers', queryClient)
      refetch()
    },
  })

  const [hiddenColumns, setHiddenColumns] = useState<any>({
    name: true,
    organizations: true,
    phone: true,
    email: true,
    status: true,
    action: true,
  })
  const handleSearch = (query: string) => {
    setParms({
      ...parms,
      queryString: query,
    })
  }
  const handleSort = (
    sortInfo: 'createdAt' | 'updatedAt' | '-createdAt' | 'name' | 'email',
  ) => {
    setParms({
      ...parms,
      sort: sortInfo,
    })
  }
  useEffect(() => {
    if (editorgData !== null) setShowOrg(true)
  }, [editorgData])

  return (
    <AppLayout>
      <PageNameHeader
        selectedRows={selectedRows}
        setAlert={setAlertModalVisible}
        CSdata={data?.records}
        deleteMulti={deleteMultipleRecords}
        addOrg={setShowOrg}
      />
      <Row>
        <Col style={{ width: '100%' }}>
          <Tabs className="orgn-tabs" defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Active" key="1">
              <ActionHeaders
                setCols={setHiddenColumns}
                hidCols={hiddenColumns}
                search={handleSearch}
                setParms={setParms}
                sort={handleSort}
                data={activeData}
                deleteMulti={deleteMultipleRecords}
                newOrg={setShowOrg}
              />
              <Row>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading || isFetching ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <OrganizerTable
                      activeData={data}
                      deleteRecord={deleteOrganizars}
                      setEditData={setEditorgData}
                      setSelectedRows={setSelectedRows}
                      hidCols={hiddenColumns}
                      parmsData={parms}
                      setParms={setParms}
                      status="active"
                      changeStatus={changeMutation}
                      refetchOrganizers={() => {}}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Pending" key="2">
              <ActionHeaders
                setCols={setHiddenColumns}
                hidCols={hiddenColumns}
                search={handleSearch}
                sort={handleSort}
              />
              <Row style={{ width: '100%' }}>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <OrganizerTable
                      activeData={data}
                      deleteRecord={deleteOrganizars}
                      setEditData={setEditorgData}
                      setSelectedRows={setSelectedRows}
                      hidCols={hiddenColumns}
                      parmsData={parms}
                      setParms={setParms}
                      status="inactive"
                      changeStatus={changeMutation}
                      refetchOrganizers={() => {}}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {alertModalVisible && (
        <Statusmodal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModalVisible}
          data={alertModalData}
          name="organizer"
          selectedRows={selectedRows}
        ></Statusmodal>
      )}
      {showOrg && (
        <CreateOrganizerModal setModal={setShowOrg} modalVisible={showOrg} />
      )}
    </AppLayout>
  )
}

export default Organizer
