import React from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { guardianStore } from 'state/family/guardian'
import CreateGuardian from './ModalContent/EmailNotFound/CreateGuardian'
import useFormSubmit from 'helper/Hooks/formSubmit'

interface GuardianCreationProps {
  familyId: string
}

const GuardianCreation: React.FC<GuardianCreationProps> = ({ familyId }) => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    guardianCreationModal,
    setGuardianCreationModal,
    guardianCounter,
    setGuardianCounter,
    setGuardianData,
    isUpdateingGuardian,
    setIsUpdatingGuardian,
  } = guardianStore(store => ({
    guardianCreationModal: store.guardianCreationModal,
    setGuardianCreationModal: store.setGuardianCreationModal,
    guardianCounter: store.guardianCounter,
    setGuardianCounter: store.setGuardianCounter,
    setGuardianData: store.setGuardianData,
    isUpdateingGuardian: store.isUpdating,
    setIsUpdatingGuardian: store.setIsUpdating,
  }))

  const hanldeClose = () => {
    if (guardianCounter === 1) {
      setGuardianCounter(0)
    } else {
      setGuardianCreationModal(false)
    }
  }

  const handleCrossClick = () => {
    setIsUpdatingGuardian(false)

    setGuardianData(null)
    setGuardianCreationModal(false)
  }

  return (
    <>
      <CustomModal
        openModal={guardianCreationModal}
        isCloseModalCustom
        closeModal={handleCrossClick}
        title={guardianCounter === 0 ? 'Create guardian' : 'Add password'}
        footer={
          <CustomModalFooter
            multibutton={guardianCounter === 0 ? false : true}
            btnAlignment={
              guardianCounter === 0 ? 'justify-end' : 'justify-between'
            }
            btnType="primary"
            btnText={
              guardianCounter === 0
                ? `${isUpdateingGuardian ? 'Update' : 'Next'}`
                : 'Back,Create guardian'
            }
            closeModal={hanldeClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <CreateGuardian form={form} familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default GuardianCreation
